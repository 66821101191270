<template>
	<article class="course__block connect">
		<h2 class="course__content-question-header">Question #{{ index }}</h2>
		<p>{{ question }}</p>
		<v-row>
			<v-col cols="4">
				<ol>
					<li v-for="element in sourcesNodes" :key="element.id">
						{{ element.content }}
					</li>
				</ol>
			</v-col>
			<v-col cols="8">
				<v-row v-for="element in shuffledTargetNodes" :key="element.id">
					<v-col cols="4">
						<v-select :items="items"></v-select>
					</v-col>

					<v-col cols="8">
						<p>{{ element.content }}</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</article>
</template>

<script>
export default {
	name: "StudentBlockConnect",
	props: {
		question: {
			type: String,
			require: true
		},
		targetNodes: {
			type: Array,
			require: true
		},
		sourcesNodes: {
			type: Array,
			require: true
		}
	},
	computed: {
		items() {
			return this.sourcesNodes.map((el, index) => index + 1);
		},
		shuffledTargetNodes() {
			return this.shuffle(this.sourcesNodes);
		}
	},
	data() {
		return {
			answerNodes: [{ source: null, target: null }],
			index: 1
		};
	},
	methods: {
		init() {
			// TODO add items to answerNodes
		},
		shuffle(array) {
			let currentIndex = array.length;
			let randomIndex = null;
			while (currentIndex !== 0) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;
				[array[currentIndex], array[randomIndex]] = [
					array[randomIndex],
					array[currentIndex]
				];
			}
			return array;
		}
	}
};
</script>

<style lang="scss" scoped></style>
